/* eslint-disable no-return-assign */
import React from 'react';
import Title from '@/components/Title';
import Link from '@/components/Link';

export default function HomeView() {
  return (
    <section className="relative pt-[37px] md:pt-[59px] mt-[138px] xl:mt-[146px] flex flex-col md:items-start md:justify-start items-center justify-center sm:min-w-[280px] sm:w-[376px] md:w-[826px] xlg:w-full max-w-[82%] xlg:max-w-[82%] md:max-w-[90%] xxs:max-w-[90%] xs:max-w-[90%] xxs:min-w-[100%] mx-auto">
      <div className="flex flex-col w-full md:flex-row md:justify-between">
        <div className="md:w-[303px] xlg:w-full xxs:mx-4 md:mx-0">
          <Title className="text-[32px] xlg:text-[60px] xlg:w-[500px] lg:w-[400px] text-left text-navy-blue xlg:leading-[81px] font-[700]">
            Transform your health through your gut.
          </Title>
          <p className="md:text-[24px] mt-[32px] text-[#484352] font-[400]">
            Vitract pairs gut microbiome technology with personalized nutrition
            to improve digestive health, metabolism, immunity, and mental
            wellness.
          </p>
        </div>
        <div>
          <img
            src="/images/vitract.gif"
            alt="Vitract"
            className="block xxs:w-[250px] xxs:h-[210px] xs:w-[376px] xs:h-[290px] md:w-[401.91px] md:h-[321.36px] xlg:w-[738px] min-w-[280px] min:h-[300px] max-w-[786px] xlg:h-[550px] 2xl:w-[738px] 2xl:h-[590px] max-h-[590px] xxs:mx-auto xxs:mt-[20px]"
          />
          <img
            className="hidden xlg:block absolute top-[98px] w-40"
            src="/images/brick.svg"
            alt=""
            data-aos="fade-down"
          />
        </div>
      </div>

      <div className="absolute xxs:top-[600px] md:top-[490px] lg:top-[450px] xlg:top-[530px] gap-x-5">
        {/* <Button
            text="Shop"
            className="text-[11px] md:text-[16px] text-white font-medium bg-primary px-[28px] py-[11px] md:py-[18.5px] md:px-[70px] rounded-[4px]"
            onClick={() => handleSignUp(null, `waitlist`)}
          /> */}

        <Link
          href="/pricing"
          className=" flex text-[11px] md:text-[16px] text-[#FFFFFF] font-medium bg-primary xxs:px-[87.5px] xxs:py-[10px] px-[28px] py-[11px] md:py-[18.5px] md:px-[70px] rounded-[4px] hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
        >
          Shop
        </Link>
      </div>
    </section>
  );
}
